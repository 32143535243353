import React from "react";
import { Media } from "@ryerson/frontend.layout";
import { Video } from "@ryerson/frontend.media";
import { Typography } from "@ryerson/frontend.typography";
import styled from "@emotion/styled";
import { ContentSection } from "@ryerson/frontend.layout";
import { useTheme } from "@ryerson/frontend.theme";
import { useApplication } from "@ryerson/frontend.application";
import { css } from "@emotion/react";
import { VideoWrapperProps } from "@components/Helper/common";
import { getVideoPadding } from "@components/Helper/commonStyles";
import { useStaticQuery, graphql } from "gatsby";
import { renderRichText } from "@components/Shared/model/Contentful";
import StayTunedComponent from "@components/Shared/StayTuned/StayTuned";

const FirstVideo = styled.div`
	width: 720px;
	display: inline-block;
	height: 396px;
	margin: 80px 0;
	vertical-align: top;
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "right", buttonSize = "md" } = props;
		return getVideoPadding(buttonOrientation, false, buttonSize);
	}}
`;

const FirstDescription = styled.div`
	width: calc(100% - 720px);
	padding-left: 6.5%;
	display: inline-block;
	vertical-align: top;
	box-sizing: border-box;
`;

const LineItem = styled.div`
	display: block;
	width: 100%;
	padding: 40px 0px;
`;

const VideoContainer = styled.div`
	width: 340px;
	display: inline-block;
	vertical-align: top;
	height: 180px;
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "right", buttonSize = "sm" } = props;
		return getVideoPadding(buttonOrientation, false, buttonSize);
	}}
`;

const Title = styled.div`
	width: 260px;
	display: inline-block;
	vertical-align: top;
	height: auto;
	margin-left: 60px;
`;

const Description = styled.div`
	width: calc(100% - 780px);
	margin-left: 120px;
	display: inline-block;
	height: auto;
	vertical-align: top;
`;

const Divider = styled.hr`
	width: 100%;
	margin: 0;
	opacity: 0.1;
`;

const Spacer = styled.div`
	display: block;
	width: 100%;
	height: 75px;
`;

const XLSpacer = styled.div`
	display: block;
	width: 100%;
	height: 280px;
`;

const MobileVideoContainer = styled.div`
	width: 100%;
	max-width: 340px;
	margin: 0 auto;
`;

const MobileSpacer = styled.div`
	display: block;
	height: 45px;
	width: 100%;
`;

const MobileVideoWrapper = styled.div`
	${(props: VideoWrapperProps) => {
		const { buttonOrientation = "top-right", buttonSize = "md" } = props;
		return getVideoPadding(buttonOrientation, true, buttonSize);
	}}
`;

const stayTunedParent = "Tutorials-VideoList";
const VideoList = () => {
	const { theme } = useTheme();
	const {
		localization: { language },
	} = useApplication();

	const queryData = useStaticQuery(graphql`
		query AllTutorials {
			allContentfulTutorial(sort: { order: DESC, fields: contentful_id }) {
				nodes {
					title
					videoId
					videoThumbnail {
						file {
							url
						}
					}
					description {
						raw
					}
					contentful_id
					ordinal
					node_locale
				}
			}
		}
	`);
	let allTutorials: any = [];
	queryData.allContentfulTutorial.nodes.forEach((node: any) => {
		if (node.node_locale.split("-").shift() === language) {
			allTutorials.push(node);
		}
	});
	allTutorials.sort(function (a: any, b: any) {
		return b.ordinal - a.ordinal;
	});

	let topVideos = allTutorials.slice(0, 5);
	let bottomVideos = allTutorials.slice(5);

	return (
		<>
			<Media greaterThanOrEqual="lg">
				<ContentSection vPadding="0">
					{topVideos.map((video: any, index: number) => {
						return (
							<React.Fragment key={index}>
								{index === 0 ? (
									<>
										<FirstVideo>
											<Video
												buttonOrientation="right"
												imageUrl={
													video.videoThumbnail.file.url +
													"?fm=jpg&fit=fill&w=655&h=396"
												}
												videoId={video.videoId}
											/>
										</FirstVideo>
										<FirstDescription>
											<Typography
												css={css`
													letter-spacing: -2px;
													margin-top: 80px;
													margin-bottom: 43px;
												`}
												variant="h2"
											>
												{video.title}
											</Typography>
											<Typography size="md" variant="p">
												{renderRichText(
													video.description.raw,
													"primary",
													null,
													null
												)}
											</Typography>
										</FirstDescription>
									</>
								) : (
									<LineItem>
										<VideoContainer>
											<Video
												buttonSize="sm"
												buttonOrientation="right"
												imageUrl={
													video.videoThumbnail.file.url +
													"?fm=jpg&fit=fill&w=290&h=180"
												}
												videoId={video.videoId}
											/>
										</VideoContainer>
										<Title>
											<Typography
												variant="h3"
												css={css`
													margin-top: 0px;
												`}
											>
												{video.title}
											</Typography>
										</Title>
										<Description>
											<Typography size="md" variant="p">
												{renderRichText(
													video.description.raw,
													"primary",
													null,
													null
												)}
											</Typography>
										</Description>
									</LineItem>
								)}
								<Divider
									css={css`
										color: ${theme.colors.primary.darkGray};
									`}
								/>
							</React.Fragment>
						);
					})}

					<Spacer />
				</ContentSection>

				<StayTunedComponent parent={stayTunedParent} />

				<ContentSection vPadding="0">
					<Spacer />
					<Divider
						css={css`
							color: ${theme.colors.primary.darkGray};
						`}
					/>
					{bottomVideos.map((video: any, index: number) => {
						return (
							<React.Fragment key={index}>
								<LineItem>
									<VideoContainer>
										<Video
											buttonSize="sm"
											buttonOrientation="right"
											imageUrl={
												video.videoThumbnail.file.url +
												"?fm=jpg&fit=fill&w=290&h=180"
											}
											videoId={video.videoId}
										/>
									</VideoContainer>
									<Title>
										<Typography
											css={css`
												margin-top: 0px;
											`}
											variant="h3"
										>
											{video.title}
										</Typography>
									</Title>
									<Description>
										<Typography size="md" variant="p">
											{renderRichText(
												video.description.raw,
												"primary",
												null,
												null
											)}
										</Typography>
									</Description>
								</LineItem>
								<Divider
									css={css`
										color: ${theme.colors.primary.darkGray};
									`}
								/>
							</React.Fragment>
						);
					})}
					<XLSpacer />
				</ContentSection>
			</Media>
			<Media lessThan="lg">
				<ContentSection>
					{topVideos.map((video: any, index: number) => {
						return (
							<MobileVideoContainer key={index}>
								<MobileVideoWrapper>
									<Video
										buttonSize="md"
										buttonOrientation="top-right"
										imageUrl={
											video.videoThumbnail.file.url +
											"?fm=jpg&fit=fill&w=340&h=224"
										}
										videoId={video.videoId}
									/>
								</MobileVideoWrapper>
								<Typography
									variant="h3"
									css={css`
										margin-top: 25px;
										margin-bottom: 10px;
									`}
									type="primary"
								>
									{video.title}
								</Typography>
								<Typography type="primary" size="md">
									{renderRichText(video.description.raw, "primary", null, null)}
								</Typography>
							</MobileVideoContainer>
						);
					})}
				</ContentSection>
				<StayTunedComponent parent={stayTunedParent} />
				<ContentSection>
					{bottomVideos.map((video: any, index: number) => {
						return (
							<MobileVideoContainer key={index}>
								<MobileVideoWrapper>
									<Video
										buttonSize="md"
										buttonOrientation="top-right"
										imageUrl={
											video.videoThumbnail.file.url +
											"?fm=jpg&fit=fill&w=340&h=224"
										}
										videoId={video.videoId}
									/>
								</MobileVideoWrapper>
								<Typography
									variant="h3"
									css={css`
										margin-top: 25px;
										margin-bottom: 10px;
									`}
									type="primary"
								>
									{video.title}
								</Typography>
								<Typography type="primary" size="md">
									{renderRichText(video.description.raw, "primary", null, null)}
								</Typography>
							</MobileVideoContainer>
						);
					})}
					<MobileSpacer />
				</ContentSection>
			</Media>
		</>
	);
};

export default VideoList;
